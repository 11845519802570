import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo";
// import Features from "components/features/TwoColSingleFeatureWithStats";
import Cards from "components/cards/PortfolioTwoCardsWithImage"
import Bordered from "components/features/ThreeColWithSideImage";
import Expertise from "components/features/TwoColWithButton"
import AboutUs from "components/cards/ProfileThreeColGrid"
import FAQ from "components/faqs/SingleCol";
import Footer from "components/footers/MiniCenteredFooter";
// import MainFeature from "components/features/TwoColWithButton.js";
// import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
// import serverRedundancyIllustrationImageSrc from "images/analytics.jpg"
// import SliderCard from "components/cards/ThreeColSlider.js";
//  import serverSecureIllustrationImageSrc from "images/server-secure-illustration.svg"

export default () => {
  return (
    <AnimationRevealPage>
      <div id="home">
        <Hero />
      </div>
      {/* <div id="ai">
        <Features />
      </div> */}
      <div id="projects">
        <Cards />
      </div>
      <div id="services">
        <Bordered />
      </div>
      <div id="expertise">
        <Expertise />
      </div>
      <div id="about-us">
        <AboutUs />
      </div>
      <div id="faq">
        <FAQ />
      </div>
      <div id="contact-us" >
        <Footer />
      </div>
    </AnimationRevealPage>
  );
}
