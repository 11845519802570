import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import Da from "../../images/da.png";


import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

// import SupportIconImage from "images/support-icon.svg";
// import ShieldIconImage from "images/shield-icon.svg";
// import CustomizeIconImage from "images/customize-icon.svg";
// import FastIconImage from "images/fast-icon.svg";
// import ReliableIconImage from "images/reliable-icon.svg";
// import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-blue-900`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-10 h-10`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-400 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = <>Our Professional <wbr/><span tw="text-blue-700">Services</span> </>  , subheading = "Services", description = "" }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    // {
    //   imageSrc: ShieldIconImage,
    //   title: "Secure",
    //   description: "We strictly only deal with vendors that provide top notch security."
    // },
    // { imageSrc: SupportIconImage, title: "24/7 Support" },
    // { imageSrc: CustomizeIconImage, title: "Customizable" },
    // { imageSrc: ReliableIconImage, title: "Reliable" },
    // { imageSrc: FastIconImage, title: "Fast" },
    // { imageSrc: SimpleIconImage, title: "Easy" }
    {
      imageSrc: "https://cdn4.iconfinder.com/data/icons/business-and-office-cute-style-vol-4-1/52/document__file__search__page__find-512.png",
      title: "Natural Language Processing",
      description: "Powering machines to read, understand and respond with our NLP processing toolkit to provide next level customer services. Inclusion of Deep Neural Networks helping us to get deeper insight of the text helping us to expand our horizon for providing chat-bots,fraud detection, text summarisation and abstraction services."
    },
    {
      imageSrc:"https://cdn4.iconfinder.com/data/icons/for-your-interface-part-1-1/128/Old_Videocamera-512.png",
      title: "Video Intelligence",
      description: "Our goal is to make models perceive the video by actually understanding features at granular level at an impeccable speed providing application specific services as super resolution, caption generation and compression."
    },
    // {
    //   imageSrc: "https://cdn4.iconfinder.com/data/icons/space-and-beyond-blue/512/space-coloured-10-512.png",
    //   title: "Reinforcement Learning",
    //   description: "Our reinforcement learning agents help to optimize out hyper parameters of our models to provide you with the with state of art accuracy at less computational expense. We use Deep RL agents for recommendation systems,chatbots,etc."
    // },
    {
      imageSrc:"https://www.hyperiondev.com/static/images/bootcamps/web-dev-icon.svg",
      title: "Full Stack Development",
      description: "We provide emersive, responsive and low latency robust websites with state of the art technology using javascript frameworks like React.js,Vue.js and the complete MERN Stack. "
    },
    {
      imageSrc:Da,
      title: "Data Analytics",
      description: "Data inherently consists of many patterns, trends hidden at its core. For every organisation data of its clients, customers and of organization is of the utmost importance.So we help you with optimising your resources,maintaining client relationship and real-time analytics."
    },
    
    // {
    //   imageSrc: "https://image.flaticon.com/icons/png/512/38/38167.png",
    //   title: "Intelligent Solutions & Services",
    //   description: "These are small things experienced on a daily basis like the way people respond/react, what are we referring to you might ask? Well small scale applications like churn analysis(Customer retention) whether you will see your customer visiting you again? Whether your content will get as much of a response as you got in the previous ones? This is a hybrid field which exist as combination of sub-domains."
    // },
    {
      imageSrc: "https://upload-icon.s3.us-east-2.amazonaws.com/uploads/icons/png/12386653691553668392-512.png",
      title: "Audio Processing",
      description: "Audio/Sound a reverberation which is believed to be the basis of this whole creaton. Our machine learning models with noise cancellation techniques would redifine your sound experience."
    },
    {
      imageSrc: "https://image.flaticon.com/icons/svg/1640/1640920.svg",
      title: "Computer Vision",
      description: "We develop algorithms which power computers them to see, understand and perceive the world in a similar way as humans do. Enabling them to process and make decisions. Having extensive applicability in medical applications by providing features as image segmentation, superresolution, object localisation, classification and image labelling."
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
