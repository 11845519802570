import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/aria-logo.jpeg";
import { ReactComponent as LinkedInIcon } from "../../images/linkedin-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as WhatsappIcon } from "../../images/whatsapp.svg";
import { ReactComponent as EmailIcon } from "../../images/gmail.svg";
import AnchorLink from 'react-anchor-link-smooth-scroll'

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`
const CopyrightNotice = tw.div``
const CompanyInfo = tw.div``

const Divider = tw.div`my-8 border-b border-blue-100`
const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} style={{ borderRadius: "5px" }} />
            <LogoText>AriaAI IntelliGen</LogoText>
          </LogoContainer>
          <LinksContainer>
            {/* <Link><AnchorLink href="#ai" >Our Expertise</AnchorLink></Link> */}
            <Link><AnchorLink href="#projects">Products</AnchorLink></Link>
            <Link><AnchorLink href="#services">Services</AnchorLink></Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href="https://wa.me/919284167337?text=Please%20Let%20me%20know%20about%20your%20product!" target="_blank">
              <WhatsappIcon />
            </SocialLink>
            <SocialLink href="mailto:aria.intelligent@gmail.com" target="_blank">
              <EmailIcon />
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/aria-ai/" target="_blank">
              <LinkedInIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com/HrushikeshP_111" target="_blank">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://www.youtube.com/channel/UCLf-xF3Yv-hD3jdfas8WftQ" target="_blank">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            <CopyrightAndCompanyInfoRow>
              <CompanyInfo>
                <CopyrightNotice>
                  Ganga Lahari, near Pratidnya Hall,<br />Vibhav Cooperative Housing Society,<br />Raghukul Society, Karve Nagar,<br />Pune, Maharashtra 411052<br />
                  <strong>INDIA <span role="img" aria-label="jsx-a11y/accessible-emoji">❤️</span></strong>
                </CopyrightNotice>
                <Divider />
                Copyright 2020 &copy; AriaAI IntelliGen
                <br />
                Illustrations designed by <a href="https://www.freepik.com/">Freepik</a>
              </CompanyInfo>
            </CopyrightAndCompanyInfoRow>
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};