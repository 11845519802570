import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
// import { ReactComponent as YoutubeIcon} from "images/youtube-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
import { ReactComponent as GithubIcon } from "images/github-icon.svg";
//import Osb from "../../images/ob.jpeg";
// import Hp from "../../images/hpatel.jpeg";
import Vr from "../../images/vr.jpeg";

const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3 text-blue-900`
const Description = tw(SectionDescription)`mx-auto text-center`

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`
const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-64 h-64 bg-contain bg-center rounded`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-blue-700`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-700 hocus:text-blue-700 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`

export default ({
  heading = "About Us!",
  subheading = "",
  description = "The aim of our team is to provide you the intelligent solutions for your business problems and help you leaverage your business.",
  cards = [
    // {
    //   imageSrc: Hp,
    //   position: "Co-Founder",
    //   name: "Hrushikesh Patel",
    //   links: [
    //     {
    //       url: "https://twitter.com/HrushikeshP_111",
    //       icon: TwitterIcon,
    //     },
    //     {
    //       url: "https://www.linkedin.com/in/hrushikesh-patel-b542b1150/",
    //       icon: LinkedinIcon,
    //     },
    //     {
    //       url: "https://www.youtube.com/channel/UCLf-xF3Yv-hD3jdfas8WftQ/",
    //       icon: YoutubeIcon,
    //     },
    //   ],
    // },
    {
      imageSrc: Vr,
      position: "CEO",
      name: "Vipul Rao",
      links: [
        {
          url: "https://twitter.com/vips1998",
          icon: TwitterIcon,
        },
        {
          url: "https://www.linkedin.com/in/vipul-rao-20a7541b6/",
          icon: LinkedinIcon,
        },
        {
          url: "https://github.com/svipulrao98",
          icon: GithubIcon,
        },
      ],
    },
    // {
    //   imageSrc: Osb,
    //   position: "Machine Learning Engineer",
    //   name: "Omkar Bharamgunde",
    //   links: [
    //     {
    //       url: "https://twitter.com/omkar_sb28",
    //       icon: TwitterIcon,
    //     },
    //     {
    //       url: "https://www.linkedin.com/in/omkarbharamgunde28/",
    //       icon: LinkedinIcon,
    //     },
    //     {
    //       url: "https://github.com/omkar2811",
    //       icon: GithubIcon,
    //     },
    //   ],
    // },
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <CardContent>
                <span className="position">{card.position}</span>
                <span className="name">{card.name}</span>
                <CardLinks>
                  {card.links.map((link, linkIndex) => (
                    <a key={linkIndex} className="link" href={link.url} target="_blank" rel="noopener noreferrer">
                      <link.icon className="icon" />
                    </a>
                  ))}
                </CardLinks>
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};
