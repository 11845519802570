import React, { useState } from 'react';
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import './index.css'
// import Review from './MakeTable';
// import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button';
// import { renderToString } from 'react-dom/server';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AnchorLink from 'react-anchor-link-smooth-scroll'

function Goto(props) {
  var style = {
    color: props.mode[6],
    borderRadius: 5,
    marginLeft: 20,
    border: "2px solid " + props.mode[4],
    boxShadow: "1px 2px 4px " + props.mode[4],
    padding: 10
  }
  return (
    <Container>
      <Row>
        <Col style={{ marginTop: 5 }}><Button style={style}><AnchorLink href='#ai' onClick={() => props.triggerNextStep()}>View Company Stats</AnchorLink></Button></Col>
        <Col style={{ marginTop: 5 }}><Button style={style}><AnchorLink href='#projects' onClick={() => props.triggerNextStep()}>View Our Products</AnchorLink></Button></Col>
        <Col style={{ marginTop: 5 }}><Button style={style}><AnchorLink href='#services' onClick={() => props.triggerNextStep()}>Explore the Services</AnchorLink></Button></Col>
        {/* <Col style={{marginTop: 5}}><Button style={style}><AnchorLink href='#expertise' onClick={() => props.triggerNextStep()}>Our Expertise </AnchorLink></Button></Col> */}
        <Col style={{ marginTop: 5 }}><Button style={style}><AnchorLink href='#about-us' onClick={() => props.triggerNextStep()}>About Us </AnchorLink></Button></Col>
        <Col style={{ marginTop: 5 }}><Button style={style}><AnchorLink href='#faq' onClick={() => props.triggerNextStep()}>Frequently Asked Questions</AnchorLink></Button></Col>
      </Row>
    </Container>
  )
}

function CustomChatbot(props) {


  const [opened, setOpen] = useState([false])

  const togChat = () => {

    if (opened[0] === false) {
      opened[0] = true
      setOpen([true])
    }
    else {
      setOpen([false])
    }
    return "next"
  }


  const togChatend = () => {
    setOpen([false])
    return "bye"
  }


  const bubbleStyle = {
    boxShadow: "1px 2px 3px black",
    marginTop: 1,
    borderRadius: 10,
  }

  // [1:background color, 2:messageBubble, 3: botFontColor, 4: headerColor, 5: userMessage, 6: tableColor, 7: userFontColor]
  const mode = ["#FDFDFE", "#F5F5F5", "black", "#4660DC", "#4D6DF7", "dark"]

  const bubbleOptionStyle = {
    color: mode[6],
    borderRadius: 5,
    marginLeft: 20,
    border: "2px solid " + mode[4],
    boxShadow: "1px 2px 4px " + mode[4]
  }

  var config = {
    // width: "100%",
    // height: "100vh",
    floating: true
  };

  const theme = {
    background: mode[0],
    fontFamily: "Arial, Helvetica, sans-serif",
    headerFontSize: "25px",
    botBubbleColor: mode[1],
    botFontColor: mode[2],
    userBubbleColor: "#2B6CB0",
    userFontColor: "white",
    headerBgColor: "#2c5282",
    headerFontColor: "white"
  };

  var steps = [
    {
      id: "greet",
      message: "Hello there!",
      hideInput: true,
      trigger: "ask"
    },
    {
      id: "ask",
      message: "Would you like some help?",
      hideInput: true,
      trigger: "ask-opt"
    },
    {
      id: "ask-opt",
      options: [
        { value: "yes", label: "Yes", trigger: "show-links" }
      ],
      hideInput: true,
    },
    {
      id: "show-links",
      message: "Sure!",
      hideInput: true,
      trigger: "1"
    },
    {
      id: "1",
      message: "I'm here to help!",
      hideInput: true,
      trigger: "2"
    },
    {
      id: "2",
      message: "Please select where you want to go!",
      hideInput: true,
      trigger: "option"
    },
    {
      id: "option",
      component: <Goto mode={mode} />,
      hideInput: true,
      waitAction: true,
      replace: true,
      trigger: togChat
    },
    {
      id: "next",
      component: <p>Taking you to the desired location</p>,
      hideInput: true,
      trigger: "next2"
    },
    {
      id: "next2",
      message: "Do you need more help?",
      hideInput: true,
      trigger: "ask-more"
    },
    {
      id: "ask-more",
      options: [
        { value: "yes", label: "Yes", trigger: "ok" },
        { value: "no", label: "No, I'll explore the page instead!", trigger: togChatend },
      ],
      hideInput: true,
    },
    {
      id: "ok",
      message: "Okay!",
      hideInput: true,
      trigger: "2"
    },
    {
      id: "bye",
      message: "Happy exploring!",
      end: true,
      hideInput: true
    }
  ];
  return (
    <ThemeProvider theme={theme}>
      <ChatBot
        enableMobileAutoFocus="true"
        botDelay="500"
        enableSmoothScroll="true"
        botAvatar="https://miro.medium.com/max/525/1*lyyXmbeoK5JiIBNCnzzjjg.png"
        hideUserAvatar={true}
        headerTitle="AriaAI Bot"
        steps={steps}
        {...config}
        bubbleOptionStyle={bubbleOptionStyle}
        avatarStylebubbleStyle={{ boxShadow: "1px 2px 3px black" }}
        bubbleStyle={bubbleStyle}
        opened={opened[0]}
        toggleFloating={togChat}
      />
    </ThemeProvider>
  );
}

export default CustomChatbot;
